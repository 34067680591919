import {
  Badge,
  Box,
  Center,
  Heading,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { ProductProps } from "../../app/index";
import { decodeHtmlCharCodes } from "../../utils/stringCodeFormatter";
import ModalDetailProduct from "./modalDetailProduct";

const ProductElement = (product: ProductProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      paddingRight="1em"
      paddingLeft="1em"
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      borderStyle="solid"
      color="wishlist.400"
    >
      <Heading
        mt="1"
        textAlign={"center"}
        fontWeight="semibold"
        fontSize="1.3em"
        as="h4"
        lineHeight="normal"
        color="wishlist.500"
        textTransform="none"
        minH={"3em"}
      >
        {decodeHtmlCharCodes(product.productname)}
      </Heading>

      <Box position="relative">
        <Badge
          borderRadius="10px"
          bgColor={"wishlist.200"}
          textAlign={"center"}
          textColor={"#fff"}
          position={"absolute"}
          zIndex={100}
          right={0}
          fontSize={"md"}
        >
          {product.fascia} €
        </Badge>

        <Center onClick={onOpen}>
          <Image
            boxSize={"-moz-fit-content"}
            src={`${product.baseURL}${product.imagename}`}
            alt={product.descrizione}
          />
        </Center>
      </Box>
      <Box p="2">
        <Center>
          <Badge
            m="0.5em"
            borderRadius="base"
            variant="subtle"
            backgroundColor="#fff"
            border="1px solid"
            padding="0.3em"
            borderColor="wishlist.400"
            fontSize="medium"
            fontWeight="bold"
            color="wishlist.400"
          >
            {product.brand}
          </Badge>
          <Box
            m="0.5em"
            borderRadius="base"
            color="wishlist.400"
            fontWeight="normal"
            backgroundColor="#fff"
          >
            {product.product_categories}
          </Box>
        </Center>
        <Box color="gray.500" noOfLines={2} marginTop="1em" marginBottom="1em">
          {decodeHtmlCharCodes(product.descrizione)}
        </Box>
        <ModalDetailProduct
          {...product}
          baseURL={product.baseURL}
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
        />
      </Box>
    </Box>
  );
};

export default ProductElement;
