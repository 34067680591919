import { VStack } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import "./App.css";
import CookiesBanner from "./components/cookies/cookiesBanner";
import ErrorView from "./components/errorView/errorView";
import { LoadingSpinner } from "./components/loadingSpinner/loadingSpinner";
import { useValidateSession } from "./hooks/index";
import { FooterSection } from "./layouts/footerSection";
import { MenuHeader } from "./layouts/menuHeader";

const queryClient = new QueryClient();

function App() {
  let [searchParams] = useSearchParams();
  const location = useLocation();
  const { user, onLoading, onError } = useValidateSession();

  return (
    <>
      {onError && (
        <ErrorView error={{ status: "302", msg: "Sessione non valida" }} />
      )}

      {onLoading && <LoadingSpinner isLoading={onLoading} />}
      {user.t && location.pathname === "/" && (
        <Navigate
          to={`/catalog?pmin=${searchParams.get(
            "pmin"
          )}&pmax=${searchParams.get("pmax")}&keywords=${searchParams.get(
            "keywords"
          )}&category=${searchParams.get("category")}`}
          replace={true}
        />
      )}
      {user.t && (
        <QueryClientProvider client={queryClient}>
          <VStack p={0}>
            <MenuHeader />
            <Outlet />
            <FooterSection />
            <CookiesBanner />
          </VStack>
        </QueryClientProvider>
      )}
    </>
  );
}

export default App;
