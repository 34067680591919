import CookieConsent from "react-cookie-consent";

type Props = {};

const CookiesBanner = (props: Props) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Prosegui"
      cookieName="wlPolicies"
      cookieValue={"true"}
      style={{ background: "#285E61" }}
      buttonStyle={{ color: "#285E61", fontSize: "1em" }}
      expires={365}
    >
      Per offrirti una migliore esperienza di navigazione e ottenere statistiche
      anonime e aggregate sull’uso dei nostri servizi questo sito utilizza soli
      cookie tecnici, anche di terze parti. Per ulteriori informazioni consulta
      la nostra{" "}
      <a
        style={{ textDecoration: "underline" }}
        href="https://www.wishlist.it/privacy-and-cookies-law"
        rel="noreferrer"
        target='_blank'
      >
        Cookie Policy
      </a>
    </CookieConsent>
  );
};

export default CookiesBanner;
