import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent, DrawerHeader,
  DrawerOverlay
} from "@chakra-ui/react";
import * as React from "react";
import { TypeCallbackSearch, TypeQuerySearch } from "../../../app/index";
import { SearchBar } from "./searchBar";
export interface HelpBannerProps {
  isOpen: boolean;
  onClose: () => void;
  btnRef: React.RefObject<HTMLButtonElement>;
  callbackSearch: TypeCallbackSearch;
  querySearch: TypeQuerySearch;
  excludeCategories: string;
}

export const FiltersBanner = ({
  isOpen,
  onClose,
  btnRef,
  callbackSearch,
  querySearch,
  excludeCategories,
}: HelpBannerProps) => {
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton colorScheme="wishlist" />
          <DrawerHeader>Filtri di ricerca</DrawerHeader>

          <DrawerBody>
            <SearchBar
              callbackSearch={callbackSearch}
              querySearch={querySearch}
              excludeCategories={excludeCategories}
            />
            <Center p="1em">
              La spedizione dei prodotti è sempre gratuita.
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
