import { useDisclosure } from "@chakra-ui/hooks";
import { useRef } from "react";

export const useFiltersBanner = () => {
  const filterBtnRef = useRef<HTMLButtonElement>(null);
  const {
    isOpen: filterIsOpen,
    onOpen: filterOnOpen,
    onClose: filterOnClose,
  } = useDisclosure();

  return { filterBtnRef, filterIsOpen, filterOnOpen, filterOnClose };
};
