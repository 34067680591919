import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Image,
} from "@chakra-ui/react";
import { HelpBanner } from "../components/helpBanner/helpBanner";
import { useHelpBanner } from "../hooks/useHelpBanner";

export const MenuHeader = () => {
  const { helpBtnRef, helpIsOpen, helpOnOpen, helpOnClose } = useHelpBanner();

  return (
    <Grid
      p={3}
      templateColumns="repeat(2, 1fr)"
      w="100%"
      bg={process.env.REACT_APP_HEADER_BG}
    >
      <GridItem>
        <Box p={5} mb='5rem'>
          <Image
            float="left"
            src={process.env.REACT_APP_LOGO}
            w={["100px", "110px"]}
          />
        </Box>
      </GridItem>
      <GridItem>
        <Box p={5}>
          <ButtonGroup
            float="right"
            variant="ghost"
            spacing="3"
            colorScheme="wishlist"
          >
            <Button
              bgColor="#fff"
              leftIcon={<InfoIcon />}
              ref={helpBtnRef}
              onClick={helpOnOpen}
              fontSize={["sm", "md"]}
              size={"sm"}
            >
              Aiuto
            </Button>
          </ButtonGroup>
          <HelpBanner
            isOpen={helpIsOpen}
            onClose={helpOnClose}
            btnRef={helpBtnRef}
          />
        </Box>
      </GridItem>
    </Grid>
  );
};
