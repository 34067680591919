import { useEffect, useState } from "react";
import { callApiValidateSession } from "../api/validateSession";
import { initialUser, updateUser } from "../features/user/userSlice";
import { WBToken } from "./../app/types/types";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
const SESSION_KEY = "s";

export const useValidateSession = () => {
  // const [user, setUser] = useState<WBToken>();
  const user: WBToken = useAppSelector((state) => state.updateUser);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [onError, setOnError] = useState(false);
  const [onLoading, setOnLoading] = useState(false);

  const updateSession = async (_user: WBToken) => {
    dispatch(updateUser(_user));
  };

  useEffect(() => {
    const validateSession = async () => {
      setOnLoading(true);
      try {
        const response = await callApiValidateSession();
        dispatch(updateUser(response));
      } catch (err: any) {
        dispatch(updateUser(initialUser));
        setOnError(true);
        setErrorMessage(err.message);
      } finally {
        setOnLoading(false);
      }
    };
    // if (user.d.length === 0 && checkToken(token)) {
    if (!user.t) {
      validateSession();
    } else if (!user.t) {
      setOnError(true);
      setErrorMessage("Sessione scaduta");
    }
    //}

    // setOnLoading(false);
  }, [user.t, dispatch]);

  return { user, onLoading, onError, errorMessage, updateSession };
};

export const setLocalSession = (token: WBToken) => {
  window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(token));
};

export const getLocalSession = () => {
  const session = window.sessionStorage.getItem(SESSION_KEY);
  if (session) {
    return JSON.parse(session);
  }
};
