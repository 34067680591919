import { useSearchParams } from "react-router-dom";
import {
  categoryList,
  REACT_APP_MAX_FILTER,
  REACT_APP_MIN_FILTER,
  TypeCategory,
  TypeQuerySearch,
} from "../app";
import _ from "lodash";

export const useFilterParams = (
  setquerySearch: (value: TypeQuerySearch) => void,
  querySearch: TypeQuerySearch
) => {
  let [searchParams] = useSearchParams();

  const keywords = searchParams.get("keywords");

  const query: TypeQuerySearch = {
    rangeValue: rangeValidation(
      searchParams.get("pmin"),
      searchParams.get("pmax")
    ),

    keyword: keywords !== "null" && keywords ? keywords : "",
    categories: categoryConverter(searchParams.get("category")?.split(",")),
  };

  if (_.isEqual(query, querySearch) === false) {
    setquerySearch(query);
  }

  return { query };
};

const categoryConverter = (catParam: string[] | undefined) => {
  const cats: TypeCategory[] = [];
  if (catParam) {
    catParam.forEach((element) => {
      categoryList.forEach((category) => {
        if (category.key === element) cats.push(category);
      });
    });
  }
  return cats;
};

const rangeValidation = (
  min: string | undefined | null,
  max: string | undefined | null
) => {
  let pmin = REACT_APP_MIN_FILTER;
  let pmax = REACT_APP_MAX_FILTER;

  if (min !== "null") {
    pmin =
      min !== undefined && min !== null ? Number(min) : REACT_APP_MIN_FILTER;
  }

  if (max !== "null") {
    pmax =
      max !== undefined && max !== null ? Number(max) : REACT_APP_MAX_FILTER;
  }

  if (pmin < 0) pmin = REACT_APP_MIN_FILTER;

  if (pmin > 3000) pmin = REACT_APP_MAX_FILTER;

  if (pmax < 0 || pmax > 3000) pmax = REACT_APP_MAX_FILTER;

  if (pmin > pmax) {
    pmin = REACT_APP_MIN_FILTER;
  }

  return [pmin, pmax];
};
