import { Center } from "@chakra-ui/react";
import * as React from "react";
import BounceLoader from "react-spinners/BounceLoader";
export interface LoadingSpinnerProps {
  isLoading: boolean;
}
export function LoadingSpinner({ isLoading }: LoadingSpinnerProps) {
  return (
    <Center h={"60vh"}>
      <BounceLoader color="#319795" loading={isLoading} size={100} />
    </Center>
  );
}
