import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import * as React from "react";
export interface HelpFAQProps {}
export const HelpFAQ = (props: HelpFAQProps) => {
  return (
    <Accordion color="wishlist.700">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="bold">
              Quali prodotti avete nel catalogo?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Il catalogo contiene un enorme scelta di prodotti. Per trovare
          facilmente quello che cerchi usa il bottone "Filtri" qui a sinistra,
          puoi selezionare l’intervallo di prezzo, la categoria o puoi scrivere
          direttamente il nome o il brand del prodotto che stai cercando.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box fontWeight="bold" flex="1" textAlign="left">
              La spedizione è sempre gratuita?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Sì, se richiedi uno dei nostri prodotti a catalogo lo spediamo
          gratuitamente. Nessun costo extra è richiesto per la spedizione.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="bold">
              Quanto tempo ho per spendere il mio buono?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Il periodo di validità del voucher è di 6 mesi dall’emissione.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="bold">
              Come procedo per ricevere il prodotto che mi serve?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Una volta individuato il prodotto che fa per te, guarda quanto costa:
          il prezzo è sempre specificato sotto al nome del prodotto, torna sulla
          piattaforma DoubleYou e ordina un codice WishList di prezzo
          corrispondente, riceverai in breve tempo sulla tua mail il codice
          WishList e le istruzioni su come usarlo.
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
