import { WBToken } from "./../../app/types/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Stato iniziale del token
export const initialUser: WBToken = {};

// Slice con action e reducers.
const userSlice = createSlice({
  name: "updateUser",
  initialState: initialUser,
  reducers: {
    updateUser(state, action: PayloadAction<WBToken>) {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
