import { Box, Button, Center, Heading, Link, Text } from "@chakra-ui/react";
import * as React from "react";
import { Link as ReachLink } from "react-router-dom";

export interface NotFoundPageProps {}
export const NotFoundPage = (props: NotFoundPageProps) => {
  
  
  return (
   <Center h={"60vh"}>
    <Box textAlign="center" py={10} px={6} >
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, wishlist.400, wishlist.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Pagina non trovata
      </Text>
      <Text color={"gray.500"} mb={6}>
        La pagina che stai cercando non esiste.
      </Text>
      <Link
        as={ReachLink}
        to="/"
        textDecoration={"none"}
        _hover={{ textDecoration: "none" }}
      >
        <Button
          colorScheme="wishlist"
          bgGradient="linear(to-r, wishlist.400, wishlist.500, wishlist.600)"
          color="white"
          variant="solid"
        >
          Torna alla home page
        </Button>
      </Link>
      </Box>
     
    </Center>
   
  );
};
