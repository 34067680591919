import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../../App";
import { CatalogPage } from "../../pages/catalogpage";
import { NotFoundPage } from "../../pages/notfoundPage";

export const MyRoutes = (props: any) => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="catalog" element={<CatalogPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
