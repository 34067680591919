import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { ProductProps } from "../../app/index";
import { useValidateSession } from "../../hooks";
import { decodeHtmlCharCodes } from "../../utils/stringCodeFormatter";

const ModalDetailProduct = (product: ProductProps) => {
  const { user } = useValidateSession();

  return (
    <>
      <Center mt={4} mb={4}>
        <Button
          onClick={product.onOpen}
          bgColor={process.env.REACT_APP_HEADER_CTA}
          color={"#ffffff"}
          _hover={{ bg: process.env.REACT_APP_HEADER_CTA }}
          _active={{
            bg: process.env.REACT_APP_HEADER_CTA,
            transform: "scale(0.98)",
            borderColor: process.env.REACT_APP_HEADER_CTA,
          }}
        >
          Dettagli
        </Button>
      </Center>

      <Modal
        onClose={product.onClose}
        isOpen={product.isOpen}
        isCentered
        scrollBehavior="inside"
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading
              textAlign={"center"}
              color="wishlist.500"
              fontSize={["xl", "2xl", "3xl"]}
            >
              {decodeHtmlCharCodes(product.productname)}
            </Heading>
          </ModalHeader>
          <Divider orientation="horizontal" borderColor={"wishlist.200"} />
          <ModalCloseButton />
          <ModalBody padding={"2em"}>
            <SimpleGrid columns={[1, 1, 2]} spacing={5} w={"100%"}>
              <Center>
                <Image
                  boxSize={["md", "lg", "xl"]}
                  fit={"contain"}
                  src={`${product.baseURL}${product.imagename}`}
                  alt={product.descrizione_lunga}
                />
              </Center>
              <Box
                padding={"1em"}
                boxShadow="-1px 0px 1px 0px rgba(0, 0, 0, 0.1)"
              >
                <Box>
                  <Badge
                    m="0.5em"
                    borderRadius="base"
                    variant="subtle"
                    backgroundColor="#fff"
                    border="1px solid"
                    padding="0.3em"
                    borderColor="wishlist.400"
                    fontWeight="bold"
                    color="wishlist.400"
                    fontSize={["sm", "md"]}
                  >
                    {product.brand}
                  </Badge>
                  <Badge
                    m="0.5em"
                    borderRadius="base"
                    variant="subtle"
                    color="wishlist.400"
                    fontWeight="normal"
                    backgroundColor="#fff"
                    fontSize={["sm", "md"]}
                  >
                    <Text>{product.product_categories}</Text>
                  </Badge>
                </Box>
                <Text
                  p={"1em"}
                  fontSize={"md"}
                  fontWeight="bold"
                  textColor={"wishlist.500"}
                  textAlign="left"
                >
                  Prezzo: {product.fascia},00 €
                </Text>
                <Text
                  mt={"2em"}
                  mb="2em"
                  fontSize={["sm", "md"]}
                  dangerouslySetInnerHTML={{
                    __html: product.descrizione_lunga,
                  }}
                />

                <Center mb={"1em"}>
                  <Button
                    onClick={() => {
                      user.redirectUrl
                        ? window.open(user.redirectUrl, "_blank")
                        : window.open("https://www.wishlist.it", "_blank");
                    }}
                    bgColor={process.env.REACT_APP_HEADER_CTA}
                    color={"#ffffff"}
                    _hover={{ bg: process.env.REACT_APP_HEADER_CTA }}
                    _active={{
                      bg: process.env.REACT_APP_HEADER_CTA,
                      transform: "scale(0.98)",
                      borderColor: process.env.REACT_APP_HEADER_CTA,
                    }}
                    fontSize={"md"}
                    display="none"
                  >
                    Richiedi
                  </Button>
                  <Button
                    ml={5}
                    onClick={product.onClose}
                    variant="outline"
                    color={process.env.REACT_APP_HEADER_CTA}
                    fontSize={"md"}
                  >
                    Chiudi
                  </Button>
                </Center>
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDetailProduct;
