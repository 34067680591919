import { TypeRangeCollection } from "./../types/types";
/**
 * CATEGORIE
 */
export const categoryList: TypeCategory[] = [
  { key: "24773", value: "Casa e Design" },
  { key: "24772", value: "Sport e Hobby" },
  { key: "24776", value: "Esperienze e Servizi" },
  { key: "24775", value: "Hi-Tec" },
  { key: "24774", value: "Gourmet" },
  { key: "24771", value: "Per Lui" },
  { key: "24711", value: "Per Lei" },
  { key: "76809", value: "Bambini" },
  { key: "598752", value: "Exclusive" },
];

export type TypeCategory = {
  key: string;
  value: string;
};

export type TypeQuerySearch = {
  categories: TypeCategory[];
  keyword: string;
  rangeValue: number[];
};

// FIXME: spostare in configurazione o comunque esterna al deploy
export const rangeCollection: TypeRangeCollection[] = [
  { idCollection: "959e6e77e39aa298c2589bb312ac6991", amount: 15 },
  { idCollection: "4f717d4188e9875f45ae123d6576b107", amount: 25 },
  { idCollection: "b146fcf3bfc56ea76d039791612cdf81", amount: 35 },
  { idCollection: "da9a86c643e157f32effce404d90ad97", amount: 50 },
  { idCollection: "456b63cb64ae3b84af9898a560f213f6", amount: 75 },
  { idCollection: "2bba43b0e8c391a4af1b549a42e41263", amount: 100 },
  { idCollection: "6cc0930bae7201f6dc4fad23f6fca4b8", amount: 125 },
  { idCollection: "98a6656b0656841930e9698be7d0ea0d", amount: 150 },
  { idCollection: "cf6fd6c4642c41a51e05ae1b354b8f9e", amount: 200 },
  { idCollection: "dbcb6393daf85be1fe9bb06ad7e4813e", amount: 250 },
  { idCollection: "1fbf2bb6839737fcab037cd4ed06042d", amount: 300 },
  { idCollection: "ae8fbfb3711f56de80563a1030147529", amount: 500 },
  { idCollection: "0284e322351b71aac452a884cffa9cf9", amount: 750 },
  { idCollection: "2a2a1cfd6a6bce3617eca9d62476647a", amount: 1000 },
  { idCollection: "da82153781c013104b6cf69f55c0a6a6", amount: 1500 },
  { idCollection: "eb07459da20dd41580dd4159be97f7b4", amount: 2000 },
  { idCollection: "b7b3a8ce3280a0c6023f2bb692c76c68", amount: 3000 },
];

export const REACT_APP_MIN_FILTER = process.env.REACT_APP_MIN_FILTER
  ? parseInt(process.env.REACT_APP_MIN_FILTER)
  : 0;
export const REACT_APP_MAX_FILTER = process.env.REACT_APP_MAX_FILTER
  ? parseInt(process.env.REACT_APP_MAX_FILTER)
  : 3000;
