import { Alert, AlertIcon } from "@chakra-ui/alert";
import { Center, Container } from "@chakra-ui/layout";
import * as React from "react";
export interface DisplayErrorProps {
  error: Error;
}
export function DisplayError(error: DisplayErrorProps) {
  return (
    <Center m={10}>
      <Container>
        <Alert status="warning" colorScheme="wishlist">
          <AlertIcon />
          Nessun prodotto trovato. Prova a cambiare i criteri di ricerca.
        </Alert>
      </Container>
    </Center>
  );
}
